// IT
export const locale = {
  lang: 'it',
  data: {
    MENU: {
      STEP_1: 'Scegli la data',
      STEP_2: 'Seleziona percorso',
      STEP_3: 'Seleziona biglietti',
      STEP_4: 'Conferma e paga',
    },
    COMMON: {
      SELECT_LOCATION: 'Seleziona la location',
      SELECT_DATE: 'Seleziona la data in cui vuoi effettuare la visita',
      PRIVACY_1: 'Ho letto ed accettato le',
      PRIVACY_2: 'Condizioni di servizio',
      PRIVACY_3: 'e',
      PRIVACY_4: 'l\'Informativa privacy',
      SELECT_COURSE: 'Seleziona il tipo di percorso che vuoi effettuare',
      OPEN_TICKET: 'Biglietto a data aperta',
      RESERVATION_DETAIL: 'Dettaglio prenotazione',
      SEATS_AVAILABLE: 'posti disponibili',
      BUY_NOW: 'Acquista ora',
      NO_ROUTE: 'Nessun percorso disponibile per',
      CHOOSE_TIME: 'Scegli l’orario della visita',
      TOTAL: 'Totale',
      TYPE_OF_TICKET: 'Tipologia di biglietti',
      TICKET_TYPE: 'Tipo biglietto',
      DISCOUNT_CODE: 'Codice sconto',
      APPLY_DISCOUNT: 'Applica sconto',
      NO_TICKET: 'Nessun biglietto disponibile',
      FOR: 'per',
      ENTER_DETAILS: 'Inserisci i tuoi dati',
      PURCHASE_DETAIL: 'Dettaglio acquisto',
      DATE_ERROR_MSG_1: 'La vendita dei biglietti per',
      DATE_ERROR_MSG_2: 'sarà disponibile dal ',
      DATE_ERROR_MSG_3: 'al ',
      NAME: 'Nome',
      SURNAME: 'Cognome',
      CONFIRM_EMAIL: 'Conferma Email',
      TELEPHONE: 'Telefono',
      COUNTRY: 'Nazione',
      REQUIRED_FIELD: 'Campo obbligatorio',
      INVALID_EMAIL: 'Inserire un indirizzo e-mail valido',
      CONFIRM_YOUR_EMAIL: 'Conferma la tua e-mail',
      SELECT_COUNTRY: 'Seleziona nazione',
      PROVINCE: 'Provincia',
      SELECT_PROVINCE: 'Seleziona provincia',
      PAY: 'Paga',
      TICKETS: 'biglietti',
      TICKET: 'biglietto',
      AMOUNT: 'IMPORTO',
      CHECKOUT_OK: 'registrato correttamente!',
      CONFIRM_PAYMENT_EMAIL: 'Ti abbiamo inviato un’e-mail di riepilogo e la conferma dell’avvenuto pagamento.',
      PAYMENT_IT_ALERT: 'È arrivato il momento di effettuare il pagamento: al termine della transazione, controlla di aver ricevuto la conferma di prenotazione, stampala o mostrala dal tuo smartphone al personale di biglietteria al momento della visita. ATTENZIONE! La ricevuta potrebbe esserti recapitata nella casella di posta indesiderata/spam.',
      PAYMENT_IT_ALERT_OPEN_TICKET: 'È arrivato il momento di effettuare il pagamento. Al termine della transazione riceverai un\'e-mail con i biglietti acquistati e una con la conferma di pagamento. ATTENZIONE! La ricevuta potrebbe esserti recapitata nella casella di posta indesiderata/spam.',
      INVITE_YOUR_FRIENDS : 'Invita i tuoi amici a vivere una delle nostre esperienze di visita, per loro un codice sconto esclusivo del 25% valido sul primo acquisto online.',
      DISCOUNT_25 : '25% di sconto',
      SHARE_WITH_FRIENDS : 'Condividi subito',
      COPIED_TO_CLIPBOARD : 'Copiato negli appunti',
      THANK_YOU_FOR: 'Grazie per aver acquistato un’esperienza di visita di Kalatà.',
      YOUR_BOOKING: 'La tua prenotazione',
      SUCCESSFULLY_REGISTERED: 'è stata registrata correttamente.',
      SHARE_WHATSAPP: 'Condividi su Whatsapp',
      SHARE_INSTAGRAM: 'Condividi su Instagram',
      SHARE_MESSENGER: 'Condividi su Messenger',
      SHARE_EMAIL: 'Condividi via email',
      COPY_TO_CLIPBOARD: 'Copia negli appunti'
    },
    BUTTON: {
      NEXT: 'Prosegui',
      BACK: 'Indietro',
      PRINT_TICKETS: 'Stampa biglietti',
      MORE_TICKETS: 'Compra altri biglietti'
    }
  }
}
