import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedDataService } from "../config/shared-data.service";
import { ApiDataService } from "../api-data.service";
import { FormDataService } from "../data/form-data.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';
declare let fbq: Function;
@Component({
  selector: "app-tab-type",
  templateUrl: "./tab-type.component.html",
  styleUrls: ["./tab-type.component.scss"],
})
export class TabTypeComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  locationName: string;
  location: object = []
  isOpenTicket: boolean = false;

  availableCourses: Array<any> = [];
  apiData: any = { selectedDate: "", policyAccepted: "" };

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formDataService: FormDataService,
    private sharedService: SharedDataService,
    public apiService: ApiDataService,
    private route: ActivatedRoute,
    private router: Router,
    public translateService: TranslateService
  ) {
    this.apiData = this.formDataService.getSelectedDate();
    //Clear cart
    this.formDataService.resetCart();
  }

  ngOnInit() {
    this.sharedService.currentMessage
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => (this.locationName = data));

    this.route.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        this.isOpenTicket = !!params.open_ticket;
        if (params.location) {
          this.sharedService
            .getLocations()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
              (res) =>
              (this.locationName =
                res.data.locations[
                  res.data.locations.findIndex(
                    (location) => location.permalink === params.location
                  )
                ].name)
            );
        }
      });

    //remove active steps
    this.formDataService.setActiveStep("step_3", false);
    this.formDataService.setActiveStep("step_4", false);

    //get api Data
    this.apiService
      .getCourses(!this.isOpenTicket ? this.apiData.selectedDate : null)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.availableCourses = data.available_courses;
        this.isLoading = false;
      });

  }

  goToNext(course: any) {
    if (this.saveStepData(course)) {
      //ste active step 3 -> Guard
      this.formDataService.setActiveStep("step_3", true);
      this.router.navigate(["/tickets"], { queryParamsHandling: "merge" });
    }
  }

  private saveStepData(course: any): boolean {
    if (!course) {
      return false;
    }
    this.formDataService.setCourseType(course);
    return true;
  }

  


  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
