import {Component, Inject, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {SharedDataService} from "../config/shared-data.service";
import {ApiDataService} from "../api-data.service";
import {Calendar} from "../data/formData.model";
import {FormDataService} from "../data/form-data.service";
import {
  IMyDpOptions,
  IMyDateModel,
  IMyCalendarViewChanged,
} from "../../my-date-picker/interfaces";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: "app-tab-date",
  templateUrl: "./tab-date.component.html",
  styleUrls: ["./tab-date.component.scss"],
})
export class TabDateComponent implements OnInit {
  private date: Date = new Date();

  public lang = localStorage.getItem("language");
  private month = (this.lang == 'it' || !this.lang) ? {
    1: "Gennaio",
    2: "Febbraio",
    3: "Marzo",
    4: "Aprile",
    5: "Maggio",
    6: "Giugno",
    7: "Luglio",
    8: "Agosto",
    9: "Settembre",
    10: "Ottobre",
    11: "Novembre",
    12: "Dicembre",
  } : {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  }

  myDatePickerInlineOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    minYear: this.date.getFullYear(),
    //maxYear: this.date.getFullYear(),
    inline: true,
    monthLabels: this.month,
    disableUntil: {
      year: this.date.getFullYear(),
      month: this.date.getMonth() + 1,
      day: this.date.getDay() - 1,
    },
    disableDays: [{year: 0, month: 0, day: 0}],
    selectorHeight: "auto",
    selectorWidth: "auto",
    allowSelectionOnlyInCurrentMonth: false,
    disableHeaderButtons: true,
    yearSelector: false,
    monthSelector: false,
    showTodayBtn: false,
  };
  localeIt: any = this.lang;
  calendar: Calendar = {
    selectedDate: false, // moment().format("YYYY-MM-DD")
    policyAccepted: false,
  };
  showErrorRange: boolean = false;
  currentLocation: string;
  dateFrom: string;
  dateTo: string;
  locationName: string;
  location: any = {};
  locations: Array<any> = [];


  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public formDataService: FormDataService,
    private sharedService: SharedDataService,
    private apiService: ApiDataService,
    private router: Router,
  ) {
    this.disableUntil();

    this.formDataService.resetCourse();
  }

  ngOnInit() {
    //rest steps
    this.formDataService.resetActiveSteps();

    //set active tab
    this.formDataService.setActiveStep("step_1", true);

    //Remove today as selected since it may be disabled
    /*this.calendar = {
      selectedDate: null, // moment().format("YYYY-MM-DD")
      policyAccepted: false
    };

    this.formDataService.setSelectedDate(this.calendar);*/

    //Get available dates API
    // @ usage Called from the onCalendarViewChanged()
    this.location = this.formDataService.getLocationUrl();

    if (this.location && this.location.permalink && !this.location.name) {
      this.sharedService
        .getLocations()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res) => {
          if (!res.success) {
            return;
          }
          this.locations = res.data.locations;
          let loc = this.locations.find(obj => obj.permalink === this.location.permalink);
          if (loc) {
            this.formDataService.setLocationUrl(loc);
            this.location = loc;
            this.injectGTMScripts();
          }
        });
    } else {
      this.injectGTMScripts();
    }
  }

  goToNext(form: any) {
    if (this.saveStepData(form)) {
      this.formDataService.setActiveStep("step_1", true);
      this.formDataService.setActiveStep("step_2", true);
      this.router.navigate(["/type"], {queryParamsHandling: "merge"});
    }
  }

  onDateChanged(event: IMyDateModel) {
    this.calendar.selectedDate = event.formatted;
    this.formDataService.setSelectedDate(this.calendar);
  }

  onCalendarViewChanged(event: IMyCalendarViewChanged) {
    this.getDatesApi(event.month.toString(), event.year.toString());
  }

  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerInlineOptions));
  }

  /**
   * Disable days till tomorrow
   */
  private disableUntil() {
    let d = new Date();

    d.setDate(d.getDate() - 1);

    let copy = this.getCopyOfOptions();

    copy.disableUntil = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };

    this.myDatePickerInlineOptions = copy;
  }


  private disableSince() {
    const copy: IMyDpOptions = this.getCopyOfOptions();
    const dateTo: Date = new Date(this.dateTo);
    copy.disableSince = {
      year: dateTo.getFullYear(),
      month: dateTo.getMonth() + 1,
      day: dateTo.getDate(),
    };
    this.myDatePickerInlineOptions = copy;
  }

  private saveStepData(form: any): boolean {
    if (!form.valid) {
      return false;
    }

    this.formDataService.setSelectedDate(this.calendar);
    return true;
  }

  private getDatesApi(month, year) {
    let calendarPost = {
      month: month,
      year: year,
    };

    this.apiService.getCalendar(calendarPost).subscribe((data) => {
      const result = this.isDateInRange(data.calendar_visible_from, data.calendar_visible_to);

      if (!result) {
        this.showErrorRange = true;
      }
      this.currentLocation = data.location.name;
      this.dateFrom = data.calendar_visible_from;
      this.dateTo = data.calendar_visible_to;
      this.disableDays(data.disabled_dates);
      this.sharedService.changeMessage(data.location.name);
      this.disableSince();
    });
  }

  private disableDays(datesApi) {
    let copy = this.getCopyOfOptions();

    let newDates = [];

    datesApi.forEach((date) => {
      let parseDate = new Date(date);

      let obj = {
        year: parseDate.getFullYear(),
        month: parseDate.getMonth() + 1,
        day: parseDate.getDate(),
      };

      newDates.push(obj);
    });

    copy.disableDays = newDates;
    this.myDatePickerInlineOptions = copy;
  }

  public getLang() {
    if (this.lang == 'en') return '-en';
    return '';
  }

  private isDateInRange(startDateStr, endDateStr) {
    const today = new Date(); // Gets the current date
    const startDate = new Date(startDateStr); // Converts the start date from the server into a Date object
    const endDate = new Date(endDateStr); // Converts the end date from the server into a Date object

    // Checks if today's date is within the range
    return (today >= startDate && today <= endDate);
  }

  injectGTMScripts() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ecommerce: null});
    window.dataLayer.push({
      event: "calendar",
      ecommerce: {
        experience: (this.location as any).name,
        content_type: 'Data specifica'
      }
    });
  }
}
