// IT
export const locale = {
  lang: 'en',
  data: {
    MENU: {
      STEP_1: 'Choose the date',
      STEP_2: 'Select the route',
      STEP_3: 'Choose tickets',
      STEP_4: 'Confirm and pay',
    },
    COMMON: {
      SELECT_LOCATION: 'Select the location',
      SELECT_DATE: 'Select the date you want to schedule the visit',
      PRIVACY_1: 'I have read and accepted the',
      PRIVACY_2: 'Terms of Service',
      PRIVACY_3: 'and the',
      PRIVACY_4: 'Privacy Policy',
      SELECT_COURSE: 'Choose the type of route you want to take',
      OPEN_TICKET: 'Open ticket',
      RESERVATION_DETAIL: 'Reservation details',
      SEATS_AVAILABLE: 'seats available',
      BUY_NOW: 'Buy now',
      NO_ROUTE: 'No route available for',
      CHOOSE_TIME: 'Choose the time of the visit',
      TOTAL: 'Total',
      TYPE_OF_TICKET: 'Type of ticket',
      TICKET_TYPE: 'Ticket type',
      DISCOUNT_CODE: 'Discount code',
      APPLY_DISCOUNT: 'Apply discount',
      NO_TICKET: 'No tickets available',
      FOR: 'for',
      ENTER_DETAILS: 'Enter your details',
      PURCHASE_DETAIL: 'Purchase detail',
      DATE_ERROR_MSG_1: 'Ticket sales for ',
      DATE_ERROR_MSG_2: 'will be available from ',
      DATE_ERROR_MSG_3: 'to ',
      NAME: 'Name',
      SURNAME: 'Surname',
      CONFIRM_EMAIL: 'Confirm Email',
      TELEPHONE: 'Telephone',
      COUNTRY: 'Country',
      REQUIRED_FIELD: 'Required field',
      INVALID_EMAIL: 'Please enter a valid email address',
      CONFIRM_YOUR_EMAIL: 'Confirm your email!',
      SELECT_COUNTRY: 'Select country',
      PROVINCE: 'Province',
      SELECT_PROVINCE: 'Select province',
      PAY: 'Pay',
      TICKETS: 'tickets',
      TICKET: 'ticket',
      AMOUNT: 'AMOUNT',
      CHECKOUT_OK: 'successfully recorded!',
      CONFIRM_PAYMENT_EMAIL: 'We have sent you a summary email and payment confirmation.',
      PAYMENT_IT_ALERT: 'It\'s time to proceed with the payment: at the end of the transaction, ensure that you have received the booking confirmation, print it or display it on your smartphone to the ticket office staff when you visit. Please be aware! The receipt could be sent to your junk/spam email folder.',
      PAYMENT_IT_ALERT_OPEN_TICKET: 'It\'s time to proceed with the payment. At the end of the transaction, you will receive an email with the tickets you purchased and another with the payment confirmation. WARNING! The receipt could be delivered to your junk/spam email folder.',
      INVITE_YOUR_FRIENDS : 'Invite your friends to experience one of our tours, they will receive an exclusive 25% discount code valid on their first online purchase.',
      DISCOUNT_25: '25% off',
      SHARE_WITH_FRIENDS : 'Share now',
      COPIED_TO_CLIPBOARD : 'Copied to clipboard',
      THANK_YOU_FOR: 'Thank you for purchasing a Kalatà visit experience.',
      YOUR_BOOKING: 'Your booking',
      SUCCESSFULLY_REGISTERED: 'has been successfully registered.',
      SHARE_WHATSAPP: 'Share on WhatsApp',
      SHARE_INSTAGRAM: 'Share on Instagram',
      SHARE_MESSENGER: 'Share on Messenger',
      SHARE_EMAIL: 'Share via email',
      COPY_TO_CLIPBOARD: 'Copy to clipboard',
    },
    BUTTON: {
      NEXT: 'Continue',
      BACK: 'Back',
      PRINT_TICKETS: 'Print tickets',
      MORE_TICKETS: 'Purchase additional tickets'
    }
  }
}
