import {Component, OnInit} from '@angular/core';
import {FormDataService} from '../data/form-data.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ApiDataService} from '../api-data.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslationService} from '../config/translation.service';
import {MatTooltip} from '@angular/material/tooltip';


const MESSAGE_OBJ_IT = {
  subject: 'Buono sconto 25% per te e i tuoi amici',
  // tslint:disable-next-line:max-line-length
  body: 'Esperienze di visita straordinarie nel cuore dell’opera d’arte! Per te uno sconto esclusivo del 25% Richiedilo subito cliccando qui bit.ly/kalata_codiceamico',
};

const MESSAGE_OBJ_EN = {
  subject: '25% discount voucher for you and your friends.',
  // tslint:disable-next-line:max-line-length
  body: 'Extraordinary visiting experiences in the heart of the artwork! Enjoy an exclusive 25% discount. Claim it now by clicking here bit.ly/kalata_codiceamico',
};


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  isOpenTicket = false;
  myOrders: any;
  private destroyed$: Subject<boolean> = new Subject<boolean>();
  currentLang = 'it';
  messaggeObjEn = {
    subject: 'Buono sconto 25% per te e i tuoi amici',
    // tslint:disable-next-line:max-line-length
    body: 'Esperienze di visita straordinarie nel cuore dell’opera d’arte! Per te uno sconto esclusivo del 25% Richiedilo subito cliccando qui bit.ly/kalata_codiceamico',
  };
  copiedToClipBoard = false;
  tooltipMessage = this.translateService.instant('COMMON.COPY_TO_CLIPBOARD');

  constructor(
    private formDataService: FormDataService,
    private route: ActivatedRoute,
    private apiService: ApiDataService,
    private translationService: TranslationService,
    private translateService: TranslateService,
  ) {
    this.translationService.getSelectedLanguage().subscribe(lang => {
      this.currentLang = lang;
    });
    // this.myOrders = this.formDataService.getOrderedItems();
  }

  ngOnInit() {

    this.route.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        if (params.order_id) {
          this.apiService
            .showOrder(params.order_id)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
              (res) => {
                this.myOrders = res.order;
                this.injectGTMScripts();
              }
            );
        }
      });

    // reset all form data
    this.formDataService.resetFormData();

    // reset active steps
    this.formDataService.resetActiveSteps();
  }


  printTickets(url: string) {
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    this.formDataService.resetCart();
    this.formDataService.resetPersonal();
    this.formDataService.setOrderedItems(null);
  }

  getDateString(d: any): string {
    const date = new Date(d); // Creating a Date object from the original date

    const day = date.getDate(); // Get the day of the month
    const month = date.getMonth() + 1; // Get the month (months in JavaScript are 0-based)
    const year = date.getFullYear(); // Get the year

    // Building the date in the dd/mm/yyyy format
    return `${day}/${month}/${year}`;
  }

  injectGTMScripts() {
    window.dataLayer = window.dataLayer || [];

    // tslint:disable-next-line:no-unused-expression
    // localStorage.getItem('dataLayer') ? window.dataLayer = JSON.parse(localStorage.getItem('dataLayer')) : '';

    const date = this.myOrders.order_items[0].time_slot.date;
    // tslint:disable-next-line:variable-name
    const content_type = date ? 'Data specifica' : 'Data aperta';

    const items = [];

    this.myOrders.order_items.forEach((item, index) => {
      item.tickets.forEach(element => {
        const obj = {
          item_name: element.name,
          item_category: content_type,
          item_variant: item.time_slot ? item.time_slot.start_at : '',
          quantity: element.quantity ? element.quantity : '',
          price: parseFloat(element.price),
        };
        items.push(obj);
      });

    });


    setTimeout(() => {
      this.forceReload(items, content_type, date);
    }, 2000);

  }


  /// Questa funzione è stato necessario richiamarla poichè GTM non funziona quando viene effettuato il redirect a questa pagina.
  /// Forzando il refresh di questa pagina GTM torna a registrare gli eventi
  /// Gli oggetti checkout-tracked e checkout-refreshed vengono rimossi forzatamente nell'onInit della pagina tab-payment.component.ts
  // tslint:disable-next-line:variable-name
  forceReload(items: any, content_type: string, date: any) {
    const isCheckoutEventTracked = localStorage.getItem('checkout-tracked');
    const isCheckOutPageRefreshed = localStorage.getItem('checkout-refreshed');
    if (!isCheckOutPageRefreshed) {
      localStorage.setItem('checkout-refreshed', 'true');
      location.reload();
    } else {
      if (!isCheckoutEventTracked) {
        window.dataLayer.push({ecommerce: null});
        window.dataLayer.push({
          event: 'checkout',
          ecommerce: {
            experience: this.myOrders.location.name,
            event_date: date ? this.getDateString(date) : '',
            transaction_id: this.myOrders.reservation_number,
            value: parseFloat(this.myOrders.total),
            currency: 'EUR',
            content_type,
            coupon: this.myOrders.discount_code ? this.myOrders.discount_code : '',
            items
          }
        });
        localStorage.setItem('checkout-tracked', 'true');
      }
      localStorage.removeItem('checkout-refreshed');
    }
  }

  getBodyMessage(): any {
    return {
      // tslint:disable-next-line:max-line-length
      subject: this.translateService.currentLang === 'it' ? 'Buono sconto 25% per te e i tuoi amici' : '25% discount voucher for you and your friends.',
      // tslint:disable-next-line:max-line-length
      body: this.translateService.currentLang === 'it' ? 'Esperienze di visita straordinarie nel cuore dell’opera d’arte! Per te uno sconto esclusivo del 25% Richiedilo subito cliccando qui bit.ly/kalata_codiceamico' : 'Extraordinary visiting experiences in the heart of the artwork! Enjoy an exclusive 25% discount. Claim it now by clicking here bit.ly/kalata_codiceamico',
    };
  }

  shareWhatsApp() {
    // window.location.href = `https://wa.me/?text=${this.messaggeObj.body}`;
    window.open(`https://wa.me/?text=${this.getBodyMessage().body}`, '_blank');
  }

  openMessenger() {
    this.copyMessage();
    const messengerUrl = 'https://m.me/?text=Hello%20and%20Welcome';
    window.open(messengerUrl, '_blank');
  }

  openInstagram() {
    this.copyMessage();
    // window.location.href = 'https://www.instagram.com/';
    window.open('https://www.instagram.com/', '_blank');
  }

  sendEmail() {
    // tslint:disable-next-line:max-line-length
    window.open(`mailto:?subject=${encodeURIComponent(this.getBodyMessage().subject)}&body=${encodeURIComponent(this.getBodyMessage().body)}`, '_blank');
  }


  copyMessage() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.getBodyMessage().body;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  onMouseEnter(tooltip: MatTooltip, event: any) {
    event.stopImmediatePropagation();
    tooltip.show();
  }

  onMouseLeave(tooltip: MatTooltip, event: any) {
    event.stopImmediatePropagation();
    if (this.copiedToClipBoard) {
      setTimeout(() => {
        this.copiedToClipBoard = false;
        tooltip.hide();
      }, 2000);
    } else {
      tooltip.hide();
    }
  }

  onCopy(tooltip: MatTooltip, event: any) {
    this.copyMessage();
    event.stopImmediatePropagation();
    this.copiedToClipBoard = true;
    this.tooltipMessage = this.translateService.instant('COMMON.COPIED_TO_CLIPBOARD');
    setTimeout(() => {
      this.tooltipMessage = this.translateService.instant('COMMON.COPY_TO_CLIPBOARD');
      tooltip.hide();
      this.copiedToClipBoard = false;
    }, 2000);
  }


  getCurrentLang() {
    return this.translateService.currentLang;
  }
}
