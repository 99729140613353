import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormDataService} from '../data/form-data.service';
import {SharedDataService} from '../config/shared-data.service';
import {ActivatedRoute} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {TranslationService} from '../config/translation.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  locationName: any;
  isOpenTicket = false;
  language: any;
  languages: any[];

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formDataService: FormDataService,
    private sharedDataService: SharedDataService,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
  ) {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramName = urlParams.get('lang');
    if (paramName) {
      // sharedDataService.queryLang = paramName;
      localStorage.setItem('language', paramName);
    }
  }

  ngOnInit() {
    this.languages = [
      {code: 'it', name: 'Italiano'},
      {code: 'en', name: 'Inglese'},
    ];
    this.translationService.getSelectedLanguage().subscribe(lang => {
      if (lang) {
        setTimeout(() => {
          this.language = lang;
          this.cdr.detectChanges();
          this.translationService.setLanguage(this.language);
        });
      } else {
        this.language = this.languages[0].code;
        this.translationService.setLanguage(this.language);
      }
    });

    this.sharedDataService.currentMessage
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => (this.locationName = data));
    this.route.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        this.isOpenTicket = !!params.open_ticket;
        if (params.location) {
          this.sharedDataService
            .getLocations()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
              (res) => {
                (this.locationName =
                  res.data.locations[
                    res.data.locations.findIndex(
                      (location) => location.permalink === params.location
                    )
                    ].name);
              }
            );
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }


  removeQueryParameter(url: string, parameter: string): string {
    const urlParts = url.split('?');
    if (urlParts.length >= 2) {
      const prefix = encodeURIComponent(parameter) + '=';
      const pars = urlParts[1].split(/[&;]/g);

      // Ricostruisce la query string escludendo il parametro specificato
      for (let i = pars.length; i-- > 0;) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      url = urlParts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
      return url;
    } else {
      return url;
    }


  }


  languageChange(ev: any) {
    const tmpLang = localStorage.getItem('language');
    this.translationService.setLanguage(this.language);
    let url = window.location.href;

    if (url.includes('lang')) {
      url = this.removeQueryParameter(url, 'lang');
      history.pushState(null, null, url);
    }


    if (url.includes('open_ticket=true')) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const location = urlParams.get('location');
      window.location.replace(`/locations?location=${location}&open_ticket=true`);
    } else {
      if (this.language !== tmpLang) {
        window.location.reload();
      }
    }


    this.translationService.setLanguage(this.language);
  }

  checkUrl() {
    return window.location.pathname !== '/locations';
  }
}
