import {Component, OnDestroy, OnInit} from "@angular/core";
import {ApiDataService} from "../api-data.service";
import {FormDataService} from "../data/form-data.service";
import {NgxSmartModalService} from "ngx-smart-modal";
import {Router, NavigationEnd, ActivatedRoute} from "@angular/router";

import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {SharedDataService} from "../config/shared-data.service";
import {TranslateService} from "@ngx-translate/core";

declare let fbq: Function;

@Component({
  selector: "app-tab-payment",
  templateUrl: "./tab-payment.component.html",
  styleUrls: ["./tab-payment.component.scss"],
})
export class TabPaymentComponent implements OnInit, OnDestroy {
  countriesList: Array<any> = new Array<any>();
  provincesList: Array<any> = new Array<any>();
  public discount_code: string;

  personalInfo: any = {};

  cartItems: Array<any> = [];

  cartTotal: any = 0;

  cartBeforeDiscount: any = 0;

  stepsData: any;

  isLoading: boolean = false;

  requiredCountryID: boolean = true;

  italyCountryID: any = 88; //Italy C_ID
  isOpenTicket: boolean = false;
  locationName: string;
  user_confirm_email_invalid: boolean = false;
  user_email_invalid: boolean = false;
  email_regex: any;
  private destroyed$: Subject<boolean> = new Subject<boolean>();
  discount_string: string;
  checkPolicyAccepted: boolean = false;
  location: object = []
  public lang = localStorage.getItem("language")

  constructor(
    private apiService: ApiDataService,
    public formDataService: FormDataService,
    public ngxSmartModalService: NgxSmartModalService,
    private sharedService: SharedDataService,
    private router: Router,
    private route: ActivatedRoute,
    public translateService: TranslateService
  ) {
    router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        // fbq("track", "AddToCart");
      }
    });
    this.email_regex = /^(?!_)\w+([\.-]?\w+)*@(?!_)\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  }

  ngOnInit() {
    //rimuovo i due oggetti dal localStorage che utilizza la pagina di checkout
    localStorage.removeItem('checkout-refreshed');
    localStorage.removeItem('checkout-tracked');

    this.route.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        this.isOpenTicket = !!params.open_ticket;
        if (params.location) {
          this.sharedService
            .getLocations()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
              (res) => {
                this.locationName =
                  res.data.locations[
                    res.data.locations.findIndex(
                      (location) => location.permalink === params.location
                    )
                    ].name

                if (this.isOpenTicket) {
                  this.injectGTMScripts();
                }
              }
            );
        }
        if (params.discount_code) this.discount_code = params.discount_code;
      });

    this.location = this.formDataService.getLocationUrl();

    //Set active tab
    this.formDataService.setActiveStep("step_4", true);

    //Get cart items
    this.cartItems = this.formDataService.getCart();


    //Check cart total
    this.cartTotal = this.formDataService.checkCartTotal();
    this.cartBeforeDiscount = this.formDataService.getCartBeforeDiscount();
    this.discount_string = this.formDataService.getDiscountCodeString();

    //Get steps info
    this.stepsData = {
      date: this.formDataService.getSelectedDate().selectedDate,
      course: this.formDataService.getCourseType().course,
    };

    //Get countries:API
    this.apiService.getCountries().subscribe((data) => {
      this.countriesList = data;
      this.personalInfo.country_id = this.italyCountryID;
    });

    //Get provinces API
    this.apiService.getProvinces().subscribe((data) => {
      this.provincesList = data;
    });


    //Get personal Data:FORM
    this.personalInfo = this.formDataService.getPersonal();

    //check Cap field on step change
    if (
      this.personalInfo.country_id != this.italyCountryID &&
      this.personalInfo.country_id !== 0
    ) {
      //0 => disabled filed
      this.requiredCountryID = false;
    }

    if (!this.isOpenTicket) {
      this.injectGTMScripts();
    }

  }

  countryChange(ev) {
    this.requiredCountryID = ev.target.value == this.italyCountryID;
    this.personalInfo.user_zip = {};
  }

  decrementQty(ticket, type, ticketIndex, typeIndex, event) {
    let ticketData = this.formDataService.getTicketStructure();

    const ticketRef = this.cartItems[ticketIndex],
      typeRef = ticketRef.types[typeIndex];

    //get check if qty between range
    let currentQty = parseInt(typeRef.modelVal);
    if (currentQty <= 0) {
      return;
    }

    //update qty of type
    typeRef.modelVal = currentQty - 1;

    //decrement total qty of ticket
    let currentTicketQty = ticketRef.calcData.totalQty;
    ticketRef.calcData.totalQty = currentTicketQty - 1;

    //Decrement TOTAL Ticket
    let currentTotal = parseFloat(ticketRef.calcData.totalPrice);
    ticketRef.calcData.totalPrice = currentTotal - parseFloat(typeRef.price);

    //check ticket total qty status
    let ticketTotalQty = parseInt(ticketRef.calcData.totalQty);
    ticketRef.calcData.available = ticketTotalQty > 0;

    //decrement ticket
    let cartType = {
      id: type.id,
      name: type.name,
      price: type.price,
      modelVal: typeRef.modelVal,
    };
    this.formDataService.decrementTicketFromCart(ticket.id, cartType);

    //Get total
    this.cartTotal = this.formDataService.getCartTotal();

    //Sync tickets Val with cart Val
    this.formDataService.syncTicketCartQty(ticket, type, typeRef.modelVal);
  }

  incrementQty(ticket, type, ticketIndex, typeIndex, event) {
    const ticketRef = this.cartItems[ticketIndex],
      typeRef = ticketRef.types[typeIndex];

    //check if the total qty is not extending the tickets available
    if (parseInt(ticketRef.calcData.totalQty) >= ticket.available_tickets) {
      return;
    }

    //get check if qty between range
    let currentQty = parseInt(typeRef.modelVal);
    if (currentQty >= ticket.available_tickets) {
      return;
    }

    //update qty
    typeRef.modelVal = currentQty + 1;

    //increment total qty of ticket
    let currentTicketQty = ticketRef.calcData.totalQty;
    ticketRef.calcData.totalQty = currentTicketQty + 1;

    //Increment TOTAL Ticket
    let currentTotal = parseFloat(ticketRef.calcData.totalPrice);
    ticketRef.calcData.totalPrice = currentTotal + parseFloat(typeRef.price);

    //check ticket total qty status
    let ticketTotalQty = parseInt(ticketRef.calcData.totalQty);

    ticketRef.calcData.available = ticketTotalQty > 0;

    //set ticket
    let cartType = {
      id: type.id,
      name: type.name,
      price: type.price,
      modelVal: typeRef.modelVal,
    };
    let cartTicket = {
      id: ticket.id,
      created_at: ticket.created_at,
      updated_at: ticket.updated_at,
      date: ticket.date,
      start_at: ticket.start_at,
      end_at: ticket.end_at,
      available_tickets: ticket.available_tickets,
      calcData: ticketRef.calcData,
      types: [cartType],
    };
    this.formDataService.incrementTicketToCart(cartTicket, cartType);

    //Get total
    this.cartTotal = this.formDataService.getCartTotal();

    //Sync tickets Val with cart Val
    this.formDataService.syncTicketCartQty(ticket, type, typeRef.modelVal);
  }

  checkInput(ele, ticketIndex, typeIndex) {
    let cart = this.formDataService.getCart();

    let currentValue = parseInt(ele.value),
      modelValue = cart[ticketIndex].available_tickets;

    if (currentValue <= 0 || currentValue > parseInt(modelValue)) {
      // remove the type of this ticket
      this.formDataService.removeTypeTicket(
        false,
        false,
        ticketIndex,
        typeIndex
      );

      //update cartTotal ain view
      this.cartTotal = this.formDataService.getCartTotal();
    }
  }

  removeType(ticket, type, ticketInd, typeInd, event) {
    //remove types + ticket
    this.formDataService.removeTypeTicket(ticket, type, ticketInd, typeInd);

    //generate total price
    this.cartTotal = this.formDataService.getCartTotal();
  }

  payTickets(form) {
    this.isLoading = true;

    if (!form.valid) {
      if (form.control.controls.user_name.invalid) {
        form.form.controls["user_name"].markAsDirty();
      }

      if (form.control.controls.user_email.invalid) {
        form.form.controls["user_email"].markAsDirty();
      }

      if (form.control.controls.user_phone.invalid) {
        form.form.controls["user_phone"].markAsDirty();
      }

      if (form.control.controls.country_id.value === 0) {
        form.form.controls["country_id"].setErrors("invalid", true);
      }

      if (form.control.controls.user_zip.invalid) {
        form.form.controls["user_zip"].markAsDirty();
      }

      this.isLoading = false;

      return;
    }

    //Set personal Data:FORM
    this.formDataService.setPersonal(this.personalInfo);

    //Do checkout
    this.doCheckout();
  }

  private doCheckout() {
    let cartItems = this.formDataService.getCart();

    if (!cartItems || cartItems.length <= 0) {
      const modalObj: Object = {
        title: "Something went wrong",
        icon: "sad.png", // => assets/img/icons/ {name}
        btnText: "Start again",
        btnRedirect: "/type",
      };

      // this.ngxSmartModalService.resetModalData('kalataModalInfo');
      this.ngxSmartModalService.setModalData(modalObj, "kalataModalInfo", true);

      this.ngxSmartModalService.open("kalataModalInfo");
      return;
    }

    let slots = [];

    cartItems.forEach((item) => {
      let _objTicket = {
        start_at: item.start_at,
        tickets: [],
      };

      let ticketItem = item;

      if (ticketItem.types && ticketItem.types.length > 0) {
        let typeArr = ticketItem.types;

        typeArr.forEach((type) => {
          let _objType = {
            quantity: type.modelVal,
            id: type.id,
          };

          _objTicket.tickets.push(_objType);
        });
      }

      //add ticket
      slots.push(_objTicket);
    });


    this.apiService
      .buyTickets(
        slots,
        this.personalInfo,
        this.isOpenTicket,
        this.discount_code,
        this.cartItems[0].date,
        (this.formDataService.getCourseType().course as any).id
      )
      .subscribe((response) => {
        if (response.success) {
          console.log(response.data.order)

          //Store response
          this.formDataService.setOrderedItems(response.data.order);
          this.formDataService.setDiscountCodeString("");
          this.formDataService.setDiscountCode("");


          this.isLoading = false;

          if (response.data.payment_url) {
            //Redirect to last page
            TabPaymentComponent.redirectToPayment(response.data.payment_url);
          } else {
            if (response.data.order) {
              this.router.navigateByUrl("/checkout?order_id=" + response.data.order.id);
            }
          }


        }
      });
  }

  public getLang() {
    if (this.lang == 'en') return '-en';
    return '';
  }

  private static redirectToPayment(url: string) {
    window.location.href = url;
  }

  validateEmail(event) {
    this.user_email_invalid = !event.target.value.match(this.email_regex);
  }


  validateConfirmEmail(event) {
    this.user_confirm_email_invalid = !event.target.value.match(this.email_regex);
  }

  getDateString(d: any): string {
    const date = new Date(d); // Creating a Date object from the original date

    const day = date.getDate(); // Get the day of the month
    const month = date.getMonth() + 1; // Get the month (months in JavaScript are 0-based)
    const year = date.getFullYear(); // Get the year

    // Building the date in the dd/mm/yyyy format
    return `${day}/${month}/${year}`;
  }


  injectGTMScripts() {

    let date_type = "Data specifica"
    let location = (this.location as any).name

    if (this.isOpenTicket) {
      date_type = "Data aperta";
      location = this.locationName;
    }
    let coupon = ""
    if (this.discount_code) {
      coupon = this.discount_code;
    }

    let total = this.cartTotal;

    let items = [];

    this.cartItems.forEach((item, index) => {

      item.types.forEach(element => {
        const obj = {
          item_id: element.id,
          item_name: element.name,
          item_category: date_type,
          item_variant: item.start_at ? item.start_at : "",
          quantity: element.modelVal ? element.modelVal : "",
          price: element.price
        }
        items.push(obj);
      });

    })

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ecommerce: null});
    window.dataLayer.push({

      event: "payment",
      ecommerce: {

        experience: location,
        event_date: this.stepsData.date ? this.getDateString(this.stepsData.date) : "",
        value: total,
        currency: "EUR",
        content_type: date_type,
        coupon: coupon,
        items: items

      }

    });
    localStorage.setItem("dataLayer", JSON.stringify(window.dataLayer));
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
