import { Injectable } from "@angular/core";
import { Calendar, Course, FormData, Personal } from "./formData.model";
import { Router } from "@angular/router";
import { TranslationService } from "../config/translation.service";

@Injectable({
  providedIn: "root",
})
export class FormDataService {
  private formData: FormData = new FormData();
  private isDateValid: boolean = false;
  private isCourseValid: boolean = false;
  private isTicketsValid: boolean = false;
  private isCartValid: boolean = false;
  private isPersonalFormValid: boolean = false;
  private discountString: string = "";
  private discountCode: string = "";
  private cartBeforeDiscount: number = 0;
  private discountAmount: number = 0;
  ticketsArrModified: Array<any> = [];
  public currentLang: string = "it";

  orderedItems: any;

  activeSteps: Object = {
    step_1: false,
    step_2: false,
    step_3: false,
    step_4: false,
  };

  constructor(private router: Router) {

  }

  //Set location URL
  setLocationUrl(link: object) {
    this.formData.location = link;
  }

  getLocationUrl(): any {
    return this.formData.location;
  }

  //Menu 'active' Classes
  setActiveStep(step, val) {
    this.activeSteps[step] = val;
  }

  getActiveStep() {
    return this.activeSteps;
  }

  resetActiveSteps() {
    this.activeSteps["step_1"] = false;
    this.activeSteps["step_2"] = false;
    this.activeSteps["step_3"] = false;
    this.activeSteps["step_4"] = false;
  }

  //Ordered Items
  setOrderedItems(data) {
    this.orderedItems = data;
  }
  getOrderedItems() {
    return this.orderedItems;
  }

  setDiscountCode(discount){
    this.discountCode = discount;
  }

  getDiscountCode(){
    return this.discountCode;
  }

  setDiscountCodeString(discount){
    this.discountString = discount;
  }
  getDiscountCodeString(){
    return this.discountString;
  }

  // Form Service Data
  getFormData(): FormData {
    // Return the entire Form Data
    return this.formData;
  }

  resetFormData(): FormData {
    // Return the form data after all this.* members had been reset
    this.formData.clearAll();
    this.isDateValid =
      this.isCourseValid =
      this.isCourseValid =
      this.isCartValid =
      this.isPersonalFormValid =
      this.isTicketsValid =
        false;
    return this.formData;
  }

  isFormValid() {
    // Return true if all forms had been validated successfully; otherwise, return false
    return (
      this.isDateValid &&
      this.isCourseValid &&
      this.isCartValid &&
      this.isPersonalFormValid &&
      this.isTicketsValid
    );
  }

  //Calendar
  getSelectedDate(): Calendar {
    return {
      selectedDate: this.formData.date,
      policyAccepted: this.formData.policyAccepted,
    };
  }

  setSelectedDate(data: Calendar) {
    this.isDateValid = true;
    this.formData.date = data.selectedDate;
    this.formData.policyAccepted = data.policyAccepted;
  }

  //Course Types
  getCourseType(): Course {
    return {
      course: this.formData.course,
    };
  }

  setCourseType(course: object) {
    this.isCourseValid = true;
    this.formData.course = course;
  }

  resetCourse() {
    this.isCourseValid = false;
    this.formData.clearCourse();
    return this.formData;
  }

  //Personal
  getPersonal(): Personal {
    return {
      user_name: this.formData.user_name,
      user_surname: this.formData.user_surname,
      user_email: this.formData.user_email,
      user_phone: this.formData.user_phone,
      country_id: this.formData.country_id,
      user_zip: this.formData.user_zip,
    };
  }

  setPersonal(data: Personal) {
    this.isPersonalFormValid = true;
    this.formData.user_name = data.user_name;
    this.formData.user_surname = data.user_surname;
    this.formData.user_email = data.user_email;
    this.formData.user_phone = data.user_phone;
    this.formData.country_id = data.country_id;
    this.formData.user_zip = data.user_zip;
  }

  resetPersonal() {
    this.isPersonalFormValid = false;
    this.formData.clearPersonal();
    return this.formData;
  }

  //Tickets
  setTicketStructure(apiTickets, isOpenTicket: boolean = false) {
    // Reset the tickets data, if the user changes to step 2
    if (this.ticketsArrModified.length > 0) {
      this.ticketsArrModified = [];
    }

    let slots: any;

    // FOR OPEN TICKETS
    if (
      isOpenTicket &&
      apiTickets.available_ticket_types
    ) {
      apiTickets.available_ticket_types.forEach((slot: any, slotIndex: any) => {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'it';
        let index = 0;
        slot = {
        id: index += 1 ,
        calcData: {
          totalPrice: 0,
          discountTotalPrice: 0,
          totalQty: 0,
          available: false,
        },
        types: [
          {
            id: slot.id,
            created_at: slot.created_at,
            updated_at: slot.updated_at,
            name: slot.translations[lang].name.text,
            description: slot.translations[lang].description.text,
            price: slot.price,
            modelName: "input__" + slot.id,
            modelVal: 0,
          },
        ],
      };
      this.ticketsArrModified.push(slot);
      })

      // if(!this.formData.course.id){
      //   this.setCourseType(apiTickets.available_ticket_types.course)
      // }

      // slots = {
      //   id: apiTickets.searched_data && apiTickets.searched_data.course ? apiTickets.searched_data.course.id : null,
      //   calcData: {
      //     totalPrice: 0,
      //     discountTotalPrice: 0,
      //     totalQty: 0,
      //     available: false,
      //   },
      //   types: [
      //     {
      //       id: apiTickets.available_ticket_types.id,
      //       created_at: apiTickets.available_ticket_types.created_at,
      //       updated_at: apiTickets.available_ticket_types.updated_at,
      //       name: apiTickets.available_ticket_types.name,
      //       description: apiTickets.available_ticket_types.description,
      //       price: apiTickets.available_ticket_types.price,
      //       modelName: "input__" + apiTickets.available_ticket_types.id,
      //       modelVal: 0,
      //     },
      //   ],
      // };
     // this.ticketsArrModified.push(slots);
    }

    // FOR NORMAL TICKETS
    if (
      apiTickets.available_time_slots &&
      apiTickets.available_ticket_types &&
      apiTickets.available_time_slots.length > 0 &&
      apiTickets.available_ticket_types.length > 0 &&
      !isOpenTicket
    ) {
      let index = 0;
      let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'it';
      apiTickets.available_time_slots.forEach((slot: any, slotIndex: any) => {
        slots = {
          id: index += 1 ,
          created_at: slot.created_at,
          updated_at: slot.updated_at,
          date: slot.date,
          start_at: slot.start_at,
          //end_at: slot.end_at,
          available_tickets: slot.available_tickets,
          calcData: {
            totalPrice: 0,
            discountTotalPrice: 0,
            totalQty: 0,
            available: false,
          },
          types: [],
        };

        apiTickets.available_ticket_types.forEach(
          (type: any, typeIndex: any) => {
            let data = {
              id: type.id,
              created_at: type.created_at,
              updated_at: type.updated_at,
              name: type.translations[lang].name.text,
              description: type.translations[lang].description.text,
              price: type.price,
              modelName: "input__" + type.id,
              modelVal: 0,
            };

            slots.types.push(data);
          }
        );
        this.ticketsArrModified.push(slots);
      });
    }


    return this.ticketsArrModified;
  }

  getTicketStructure() {
    return this.ticketsArrModified;
  }

  generateTicketTotalPrice(ticket, type) {
    let ticketData = this.getTicketStructure();

    //find ticket & index
    let ticketModItem = ticketData.filter((item) => item.id === ticket.id),
      ticketModIndex = ticketData.findIndex((item) => item.id === ticket.id);

    //find type & index => ticket
    let typesModArr = ticketModItem[0].types,
      indexOfType = typesModArr.findIndex((item) => item.id === type.id);

    let typePrice = typesModArr[indexOfType].price;
    let typeQty = typesModArr[indexOfType].modelVal;

    let currPrice = this.ticketsArrModified[ticketModIndex].calcData.totalPrice;
    let currQty = this.ticketsArrModified[ticketModIndex].calcData.totalQty;

    this.ticketsArrModified[ticketModIndex].calcData.totalPrice =
      currPrice - typePrice * typeQty;
    this.ticketsArrModified[ticketModIndex].calcData.totalQty =
      currQty - typeQty;
  }

  updateTicketTypeQty(ticketIndex, typeIndex, typeVal) {
    this.ticketsArrModified[ticketIndex].types[typeIndex].modelVal = typeVal;
  }

  //Cart
  incrementTicketToCart(ticket, typeReq) {
    let cart = this.getCart();

    if (cart.length > 0) {
      let newCart = true;

      for (let i = 0; i < cart.length; i++) {
        let newTypes = true;

        let cartItem = cart[i];

        if (cartItem.id === ticket.id) {
          newCart = false;

          //check types

          let cartItemTypes = cartItem.types;

          for (let j = 0; j < cartItemTypes.length; j++) {
            let type = cartItemTypes[j];

            if (type.id === typeReq.id) {
              newTypes = false;
              type.modelVal = typeReq.modelVal;
              break;
            }
          }

          if (newTypes) {
            cartItemTypes.push(typeReq);
          }

          break;
        }
      }

      if (newCart) {
        this.formData.cart.push(ticket);
      }
    } else {
      this.formData.cart.push(ticket);
    }

    //calculate new cart total
    this.checkCartTotal();
  }

  decrementTicketFromCart(ticketID, typeReq) {
    let cart = this.getCart();

    if (cart.length > 0) {
      for (let i = 0; i < cart.length; i++) {
        let ticket = cart[i];

        if (ticket.id === ticketID) {
          //check type qty
          let types = ticket.types;

          let typesEmpty = false;

          for (let j = 0; j < types.length; j++) {
            let type = types[j];

            if (type.id === typeReq.id) {
              if (typeReq.modelVal === 0) {
                types.splice(j, 1);
                typesEmpty = true;
              } else {
                type.modelVal = typeReq.modelVal;
              }

              break;
            }
          }

          if (typesEmpty && types.length === 0) {
            this.formData.cart.splice(i, 1);
          }

          break;
        }
      }

      //calculate new cart total
      this.checkCartTotal();
    }
  }

  removeTypeTicket(ticketReq, typeReq, ticketIndex, typeIndex) {
    let cartItems = this.formData.cart;

    if (cartItems && cartItems.length > 0) {
      let ticketElement = this.formData.cart[ticketIndex];

      if (ticketElement) {
        //remove types
        ticketElement.types.splice(typeIndex, 1);

        //check if types are empty, remove the ticket from cart | calc data based on types
        if (ticketElement.types.length <= 0) {
          cartItems.splice(ticketIndex, 1);
        } else {
          //calc Data for this ticket
          ticketElement.calcData =
            FormDataService.ticketCalcData(ticketElement);
        }

        //update cart total
        this.checkCartTotal();
      }
    }

    this.generateTicketTotalPrice(ticketReq, typeReq);

    this.syncTicketCartQty(ticketReq, typeReq, 0);
  }

  static ticketCalcData(ticket) {
    let calcData = {
      totalPrice: ticket.totalPrice,
      totalQty: ticket.totalQty,
      available: ticket.available,
    };

    let newTotalPrice = 0;
    let newTotalQty = 0;

    let types = ticket.types;

    for (let i = 0; i < types.length; i++) {
      newTotalQty += types[i].modelVal;
      newTotalPrice +=
        parseFloat(types[i].modelVal) * parseFloat(types[i].price);
    }

    calcData.totalPrice = newTotalPrice;
    calcData.totalQty = newTotalQty;
    calcData.available = newTotalQty > 0;

    return calcData;
  }

  getCart() {
    if (this.formData.cart.length > 0) {
      this.isCartValid = true;
    }

    return this.formData.cart;
  }

  getCartTotal() {
    return this.formData.cartTotal;
  }

  getCartBeforeDiscount() {
    return this.cartBeforeDiscount;
  }

  setDiscountAmount(discount){
    this.discountAmount = discount.total_amount;
  }

  checkCartTotal() {
    let cart = this.getCart();

    if (cart.length > 0) {
      let total = 0;
      let before_discount = 0;

      // CONTROL IF DISCOUNT EXIST TO CALCULATE IT ON TOTAL
      if (!cart.find((ticket) => ticket.calcData.discountTotalPrice > 0)) {
        cart.forEach((ticket) => {
          ticket.types.forEach((type) => {
            total += type.price * type.modelVal;
          });
        });
      } else {
        cart.forEach((ticket) => {
          before_discount += ticket.calcData.totalPrice;
          if (ticket.calcData.discountTotalPrice > 0){
            total += ticket.calcData.discountTotalPrice;
          }
          else{
            total += ticket.calcData.totalPrice;
          }
        });
      }

      if(this.discountAmount && this.discountAmount > 0){
        total = this.discountAmount;
      }
      
      this.formData.cartTotal = total;
      this.cartBeforeDiscount = before_discount;

      return this.formData.cartTotal;
    } else {
      this.cartIsEmpty();
      return;
    }
  }

  resetCart() {
    this.isCartValid = false;
    this.formData.clearCart();
    return this.formData;
  }

  cartIsEmpty() {
    this.setActiveStep("step_4", false);

    this.formData.cartTotal = 0;

    this.router.navigate(["/tickets"], { queryParamsHandling: "merge" });
  }

  /**
   * Update tickets qty on step 3 based on values changed on step 4
   * @param ticket
   * @param type
   * @param typeVal
   */
  syncTicketCartQty(ticket, type, typeVal) {
    let ticketData = this.getTicketStructure();

    //find ticket & index
    let ticketModItem = ticketData.filter((item) => item.id === ticket.id),
      ticketModIndex = ticketData.findIndex((item) => item.id === ticket.id);

    //find type & index => ticket
    let typesModArr = ticketModItem[0].types,
      indexOfType = typesModArr.findIndex((item) => item.id === type.id);

    //update this ticket structure
    this.updateTicketTypeQty(ticketModIndex, indexOfType, typeVal);
  }
}
