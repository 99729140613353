import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule, Routes} from '@angular/router';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {MyDatePickerModule} from '../my-date-picker';
import { TranslateModule } from '@ngx-translate/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';

import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {TabsComponent} from './tabs/tabs.component';
import {TabDateComponent} from './tab-date/tab-date.component';
import {TabTypeComponent} from './tab-type/tab-type.component';
import {TabTicketsComponent} from './tab-tickets/tab-tickets.component';
import {FormDataService} from './data/form-data.service';
import {TabPaymentComponent} from './tab-payment/tab-payment.component';

// Guards
import {StepOneGuardService} from './guards/step-one-guard.service';
import {StepTwoGuardService} from './guards/step-two-guard.service';
import {StepThreeGuardService} from './guards/step-three-guard.service';
import {StepFourGuardService} from './guards/step-four-guard.service';

// Register Local Currency Types in 'IT'
import it from '@angular/common/locales/it';
import {registerLocaleData} from '@angular/common';
import {AppHolderComponent} from './app-holder/app-holder.component';
import { LocationsComponent } from './locations/locations.component';
import { CheckoutComponent } from './checkout/checkout.component';

registerLocaleData(it);

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'locations',
    pathMatch: 'full'
  },
  {
    path: 'locations',
    component: LocationsComponent
  },
  {
    path: 'calendar',
    component: TabDateComponent,
    canActivate: [StepOneGuardService]
  },
  {
    path: 'type',
    component: TabTypeComponent,
    canActivate: [StepTwoGuardService]
  },
  {
    path: 'tickets',
    component: TabTicketsComponent,
    canActivate: [StepThreeGuardService]
  },
  {
    path: 'payment',
    component: TabPaymentComponent,
    canActivate: [StepFourGuardService]
  },
  // Usato solo in caso di biglietti gratuiti altrimenti la pagina è generata dal server dopo il pagamento
  {
    path: 'checkout',
    component: CheckoutComponent,
  },
  {path: '**', redirectTo: '/locations'}
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    TabsComponent,
    TabDateComponent,
    TabTypeComponent,
    TabTicketsComponent,
    TabPaymentComponent,
    CheckoutComponent,
    AppHolderComponent,
    LocationsComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    FormsModule,
    HttpClientModule,
    MyDatePickerModule,
    NgxSmartModalModule.forRoot(),
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    MatTooltipModule,
  ],
  providers: [
    StepTwoGuardService,
    StepThreeGuardService,
    StepFourGuardService,
    // CheckoutGuardService,
    {
      provide: FormDataService,
      useClass: FormDataService
    },
    {
      provide: LOCALE_ID,
      useValue: 'it-IT'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
